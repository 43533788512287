

import {
    IonContent,
    IonItem,
    IonLabel,
    IonIcon,
    IonPage,
    loadingController
} from '@ionic/vue';
import {computed, defineComponent, ref} from 'vue';
import FixedFooter from "@/components/FixedFooter.vue";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {execute, executeWithoutSpinner} from "@/mixins/LoadingMixin";
import {getJourneySet} from "@/services/JourneySetService";
import {getTemplate} from "@/services/TemplateService";
import AudioPicker from '@/components/AudioPicker.vue';
import VisualTabs from "@/components/VisualTabs.vue";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {JourneySet} from '@/models/JourneySetModel';
import {Journey, validateJourney} from "@/models/JourneyModel";
import {getJourney, updateJourney} from "@/services/JourneyService";
import router from "@/router";
import { closeOutline, chevronDownOutline, chevronUpOutline, checkmarkOutline } from 'ionicons/icons';
import {ScriptureSelection} from "@/models/ScriptureSelectionModel";
import {useStore} from "vuex";
import MarkdownWYSIWYG from "@/components/MarkdownWYSIWYG.vue";
import ScripturePicker from '@/components/ScripturePicker.vue';
import VideoPicker from '@/components/Video/VideoPicker.vue';
import { onBeforeRouteUpdate } from 'vue-router';
import TemplateItemPlaceholder from '@/components/TemplateItemPlaceholder.vue';
import { JourneyTab } from '@/models/JourneyTabModel';

export default defineComponent({
    name: 'ScriptureContent',
    components: {
        VisualTabs,
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        AudioPicker,
        FixedFooter,
        MarkdownWYSIWYG,
        VideoPicker,
        ScripturePicker,
        TemplateItemPlaceholder
    },
    props: {
        journeySetId: {
            type: String,
            required: true
        },
        journeyId: {
            type: String,
            required: true
        }
    },
    async ionViewWillLeave() {
        await this.saveProgress();
    },
    async ionViewWillEnter() {
        this.contentLoaded = false;
        await this.store.dispatch('app/setPageTitle', this.language.journeyTab2);
        await execute(async () => {
            this.journeySet = await getJourneySet(this.journeySetId);
            this.journey = await getJourney(this.journeyId);
            this.journey = validateJourney(this.journey);
            this.selectedScriptureReferences = this.journey.scriptureSelections;
            this.template = await getTemplate(this.journeySet.templateId);
            if (this.template.enableCustomContent == undefined) this.template.enableCustomContent = false;
            this.contentLoaded = true;
            this.templateTab = this.template.tabs[1];
        }, this.language.errors.loadingJourney);
        this.scriptureContent.$el.scrollToTop(300);
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const contentLoaded = ref(false);
        const template = ref({} as JourneyTemplate);
        const journeySet = ref({} as JourneySet);
        const journey = ref({} as Journey);
        const scriptureContent = ref();
        const hasFurtherEditing = computed(() => template.value.tabsCount === 4);
        const nextText = computed(() => (hasFurtherEditing.value || template.value.enableCustomContent) ? language.value.next : language.value.finishAndSave);
        const selectedScriptureReferences = ref([] as ScriptureSelection[]);
        const tabSpecificScriptureReferences = computed(() => selectedScriptureReferences.value.filter((reference) => reference.tabNum == 2))
        const templateTab = ref({} as JourneyTab);
        const activeTabs = computed(() => {
            const result: number[] = [1];
            if (hasFurtherEditing.value) result.push(2);
            if (template.value.enableCustomContent) {
                result.push(0);
                result.push(hasFurtherEditing.value ? 3 : 2);
            }
            return result
        })

        const hasVideo = computed(() => journey.value.videoLabel || journey.value.videoUrl);
        
        const save = async () => {
            await executeWithoutSpinner(async() => {
                journey.value.scriptureSelections = selectedScriptureReferences.value;
                await updateJourney(journey.value);
            }, language.value.errors.savingJourney);
        }

        const saveProgress = async () => {
            const loading = await loadingController.create({
                message: language.value.savingProgress,
            });
            await loading.present();
            await save();
            await loading.dismiss();
        }
        
        const saveAndNext = async () => {
            await saveProgress();
            if (hasFurtherEditing.value) {
                await router.push({
                    name: 'extraContent',
                    params: props });
            } else if (template.value.enableCustomContent) {
                await router.push({
                    name: 'finalContent',
                    params: props })
            } else {
                await router.push({
                    name: 'journeySetJourneys',
                    params: {journeySetId: props.journeySetId}
                });
            }
        }

        const handleAddScriptureRef = (newRef: ScriptureSelection) => {
            selectedScriptureReferences.value.push(newRef);
        }

        const handleRemoveScriptureRef = (id: string) => {
            const index = selectedScriptureReferences.value.findIndex((reference) => reference.id == id);
            selectedScriptureReferences.value.splice(index, 1);
        }

        onBeforeRouteUpdate(async (to, from, next) => {
            //scriptureContent.value.$el.scrollToTop(300);
        });
        


        return {
            store,
            language,
            saveProgress,
            save,
            template,
            journeySet,
            journey,
            scriptureContent,
            closeOutline,
            chevronDownOutline,
            chevronUpOutline,
            checkmarkOutline,
            selectedScriptureReferences,
            tabSpecificScriptureReferences,
            contentLoaded,
            hasVideo,
            activeTabs,
            handleAddScriptureRef,
            handleRemoveScriptureRef,
            footerButtons: computed(() => new FooterButtonsBuilder()
                .addClearBackButton()
                .addPrimaryButton( hasFurtherEditing.value, async () => await saveAndNext(), nextText.value).create()),
            templateTab
        }
    }
});
